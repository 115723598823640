import { ChannelLabel } from '@MP/feature/scan/type';

export interface Quality {
  channel: ChannelLabel;
  value: number;
}

export interface EEGPrepareInfo {
  impedanceList: Quality[];
  sqiList: Quality[];
}

export enum ChannelNumber {
  Fp1 = 6,
  Fp2 = 7,
  F7 = 20,
  F3 = 16,
  Fz = 24,
  F4 = 17,
  F8 = 21,
  T3 = 36,
  C3 = 42,
  Cz = 46,
  C4 = 43,
  T4 = 37,
  T5 = 64,
  P3 = 60,
  Pz = 68,
  P4 = 61,
  T6 = 66,
  O1 = 74,
  O2 = 75,
  HRV = 113,
  ANNOTATION = 112,
}

export type SubscribeId = number;

export enum ModuleState {
  OFFLINE = 'OFFLINE',
  IDLE = 'IDLE',
  READY = 'READY',
  RECORDING = 'RECORDING',
  DATA_RETENTION = 'DATA_RETENTION',
  DISPOSING = 'DISPOSING',
}
