import { useReloadEdfFiles } from '@MP/api/scanApi';
import { usePatientStore } from '@MP/feature/patient/PatientProvider';
import MinddImage from '@MP/img/MinddImage';
import { Box, Stack, Typography } from '@mui/joy';
import { observer } from 'mobx-react-lite';
import React from 'react';
import PatientLeftContents from './PatientLeftContents';
import PatientPageStore, { PatientPageStoreContext } from './PatientPageStore';
import PatientRightContents from './PatientRightContents';

const commonStyle = { p: '8px', flexGrow: 1, bgcolor: '#F5F5F5', minWidth: '1300px' };

const expandedStyle = {
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  bgcolor: '#F5F5F5',
  zIndex: 2,
};
const PatientPage = observer(() => {
  const pageStore = React.useMemo(() => new PatientPageStore(), []);
  const store = usePatientStore();
  const { mutateAsync: reloadEdfFiles } = useReloadEdfFiles();

  //TODO: 상태변경에 따른 bdf 파일목록 갱싱 로직 필요

  return (
    <PatientPageStoreContext.Provider value={pageStore}>
      <Stack direction={'row'} sx={{ height: '100%' }}>
        <PatientLeftContents />
        <Box height={'100%'} sx={pageStore.expanded ? expandedStyle : commonStyle}>
          {!store.user && (
            <Stack spacing={'32px'} sx={{ height: '100%', p: 10 }} alignItems={'center'} justifyContent={'center'}>
              <MinddImage width={200} />
              <Stack alignItems={'center'} spacing={'8px'}>
                <Typography level="body-md">아직 추가된 환자가 없습니다. </Typography>
                <Typography level="body-md">환자 추가 후 MINDD의 다양한 기능을 사용해보세요. </Typography>
              </Stack>
            </Stack>
          )}
          {store.user && <PatientRightContents />}
        </Box>
      </Stack>
    </PatientPageStoreContext.Provider>
  );
});

export default PatientPage;
