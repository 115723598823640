/* tslint:disable */
/* eslint-disable */
/**
 * MINDD SCAN2 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Registration } from '../model';
// @ts-ignore
import type { ScanModuleRegistration } from '../model';
/**
 * ModuleControllerApi - axios parameter creator
 * @export
 */
export const ModuleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 등록된 스캔 모듈 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/modules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 스캔 모듈 등록
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (deviceSerial: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSerial' is not null or undefined
            assertParamExists('register', 'deviceSerial', deviceSerial)
            const localVarPath = `/modules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deviceSerial !== undefined) {
                localVarQueryParameter['deviceSerial'] = deviceSerial;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Session info, Session state, Sensor status... etc 가 요청됨. 응답은 socket으로 전달됨
         * @summary 모듈 상태 요청
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        request: async (deviceSerial: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSerial' is not null or undefined
            assertParamExists('request', 'deviceSerial', deviceSerial)
            const localVarPath = `/modules/{deviceSerial}/request/status/all`
                .replace(`{${"deviceSerial"}}`, encodeURIComponent(String(deviceSerial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Session info, Session state, Sensor status... etc 가 요청됨. 응답은 socket으로 전달됨
         * @summary 병원에 속한 모든 모듈 상태 요청
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/modules/request/status/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 스캔 모듈 해제
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unregister: async (deviceSerial: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceSerial' is not null or undefined
            assertParamExists('unregister', 'deviceSerial', deviceSerial)
            const localVarPath = `/modules/{deviceSerial}`
                .replace(`{${"deviceSerial"}}`, encodeURIComponent(String(deviceSerial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModuleControllerApi - functional programming interface
 * @export
 */
export const ModuleControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModuleControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 등록된 스캔 모듈 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScanModuleRegistration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleControllerApi.list']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 스캔 모듈 등록
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(deviceSerial: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Registration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(deviceSerial, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleControllerApi.register']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Session info, Session state, Sensor status... etc 가 요청됨. 응답은 socket으로 전달됨
         * @summary 모듈 상태 요청
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async request(deviceSerial: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.request(deviceSerial, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleControllerApi.request']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Session info, Session state, Sensor status... etc 가 요청됨. 응답은 socket으로 전달됨
         * @summary 병원에 속한 모든 모듈 상태 요청
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleControllerApi.requestAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 스캔 모듈 해제
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unregister(deviceSerial: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScanModuleRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unregister(deviceSerial, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModuleControllerApi.unregister']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ModuleControllerApi - factory interface
 * @export
 */
export const ModuleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModuleControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary 등록된 스캔 모듈 목록 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: any): AxiosPromise<Array<ScanModuleRegistration>> {
            return localVarFp.list(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 스캔 모듈 등록
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(deviceSerial: string, options?: any): AxiosPromise<Registration> {
            return localVarFp.register(deviceSerial, options).then((request) => request(axios, basePath));
        },
        /**
         * Session info, Session state, Sensor status... etc 가 요청됨. 응답은 socket으로 전달됨
         * @summary 모듈 상태 요청
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        request(deviceSerial: string, options?: any): AxiosPromise<void> {
            return localVarFp.request(deviceSerial, options).then((request) => request(axios, basePath));
        },
        /**
         * Session info, Session state, Sensor status... etc 가 요청됨. 응답은 socket으로 전달됨
         * @summary 병원에 속한 모든 모듈 상태 요청
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAll(options?: any): AxiosPromise<void> {
            return localVarFp.requestAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 스캔 모듈 해제
         * @param {string} deviceSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unregister(deviceSerial: string, options?: any): AxiosPromise<ScanModuleRegistration> {
            return localVarFp.unregister(deviceSerial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModuleControllerApi - object-oriented interface
 * @export
 * @class ModuleControllerApi
 * @extends {BaseAPI}
 */
export class ModuleControllerApi extends BaseAPI {
    /**
     * 
     * @summary 등록된 스캔 모듈 목록 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleControllerApi
     */
    public list(options?: RawAxiosRequestConfig) {
        return ModuleControllerApiFp(this.configuration).list(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 스캔 모듈 등록
     * @param {string} deviceSerial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleControllerApi
     */
    public register(deviceSerial: string, options?: RawAxiosRequestConfig) {
        return ModuleControllerApiFp(this.configuration).register(deviceSerial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Session info, Session state, Sensor status... etc 가 요청됨. 응답은 socket으로 전달됨
     * @summary 모듈 상태 요청
     * @param {string} deviceSerial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleControllerApi
     */
    public request(deviceSerial: string, options?: RawAxiosRequestConfig) {
        return ModuleControllerApiFp(this.configuration).request(deviceSerial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Session info, Session state, Sensor status... etc 가 요청됨. 응답은 socket으로 전달됨
     * @summary 병원에 속한 모든 모듈 상태 요청
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleControllerApi
     */
    public requestAll(options?: RawAxiosRequestConfig) {
        return ModuleControllerApiFp(this.configuration).requestAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 스캔 모듈 해제
     * @param {string} deviceSerial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleControllerApi
     */
    public unregister(deviceSerial: string, options?: RawAxiosRequestConfig) {
        return ModuleControllerApiFp(this.configuration).unregister(deviceSerial, options).then((request) => request(this.axios, this.basePath));
    }
}

