import SignalFileReader from '@MP/util/signal/SignalFileReader';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ConfigControllerApi, EegQualityControllerApi, ModuleControllerApi, SensorControllerApi } from './scan/api';
import { SessionControllerApi } from './scan/api/session-controller-api';
import { RecFile, Type } from './scan/model';
import { ModuleStateChangeError } from './typs';

const sessionControllerApi = new SessionControllerApi(undefined, 'https://scan2-api.dev.ybrainlab.com');
const qualityControllerApi = new EegQualityControllerApi(undefined, 'https://scan2-api.dev.ybrainlab.com');
const sensorControllerApi = new SensorControllerApi(undefined, 'https://scan2-api.dev.ybrainlab.com');
const configControllerApi = new ConfigControllerApi(undefined, 'https://scan2-api.dev.ybrainlab.com');
const moduleControllerApi = new ModuleControllerApi(undefined, 'https://scan2-api.dev.ybrainlab.com');

export const useFetchModuleSerials = () => {
  return useQuery({
    queryKey: ['modules'],
    queryFn: () => moduleControllerApi.list().then((res) => res.data.map((v) => v.deviceSerial)),
  });
};

export const useLockSession = (type: Type) => {
  return useMutation<void, ModuleStateChangeError, { userId: string; deviceSerial: string }>({
    mutationFn: async ({ userId, deviceSerial }) => {
      try {
        await sessionControllerApi.createLock({ sensorType: type, deviceSerial: deviceSerial, subjectId: userId });
      } catch (e) {
        throw new ModuleStateChangeError(`Session Lock  실패`);
      }
    },
  });
};

export const useUnLockSession = () => {
  return useMutation<void, ModuleStateChangeError, { deviceSerial: string }>({
    mutationFn: async ({ deviceSerial }) => {
      try {
        await sessionControllerApi.deleteLock(deviceSerial);
      } catch (e) {
        throw new ModuleStateChangeError(`Session UnLock  실패`);
      }
    },
  });
};

export const useStartEEG_SQI = () => {
  return useMutation<void, ModuleStateChangeError, { deviceSerial: string }>({
    mutationFn: async ({ deviceSerial }) => {
      try {
        await qualityControllerApi.startSqi(deviceSerial);
      } catch (e) {
        throw new ModuleStateChangeError(`모듈 Start SQI 실패`);
      }
    },
  });
};

export const useStopEEG_SQI = () => {
  return useMutation<void, ModuleStateChangeError, { deviceSerial: string }>({
    mutationFn: async ({ deviceSerial }) => {
      try {
        await qualityControllerApi.stopSqi(deviceSerial);
      } catch (e) {
        throw new ModuleStateChangeError(`모듈 STOP SQI 실패`);
      }
    },
  });
};

export const useStartSignal = (type: Type) => {
  return useMutation<void, ModuleStateChangeError, { deviceSerial: string }>({
    mutationFn: async ({ deviceSerial }) => {
      try {
        await sensorControllerApi.startTransmission(deviceSerial, type);
      } catch (e) {
        throw new ModuleStateChangeError(`모듈 Start 실패`);
      }
    },
  });
};

export const useStopSignal = (type: Type) => {
  return useMutation<void, ModuleStateChangeError, { deviceSerial: string }>({
    mutationFn: async ({ deviceSerial }) => {
      try {
        await sensorControllerApi.stopTransmission(deviceSerial, type);
      } catch (e) {
        throw new ModuleStateChangeError(`모듈 STOP 실패`);
      }
    },
  });
};

export const useStartRecording = () => {
  return useMutation<void, ModuleStateChangeError, { deviceSerial: string }>({
    mutationFn: async ({ deviceSerial }) => {
      try {
        await sensorControllerApi.startRecording(deviceSerial);
      } catch (e) {
        throw new ModuleStateChangeError(`모듈 START RECORDING 실패`);
      }
    },
  });
};

export const useStopRecording = (type: Type) => {
  return useMutation<void, ModuleStateChangeError, { deviceSerial: string; save: boolean }>({
    mutationFn: async ({ deviceSerial, save }) => {
      try {
        await sensorControllerApi.stopRecording(deviceSerial, type, save);
      } catch (e) {
        throw new ModuleStateChangeError(`모듈 STOP RECORDING 실패`);
      }
    },
  });
};

export const useFetchSignalFile = (type: Type, fileName?: string) => {
  return useQuery({
    queryKey: ['signal_file', fileName, type],
    queryFn: async () => {
      // const res = await bdfControllerApi.getBdfFile(type, fileName!, { responseType: 'blob' });
      // const buffer = await res.data.arrayBuffer();
      // return new SignalFileReader(buffer).read();
      return new SignalFileReader(new ArrayBuffer(1)).read();
    },
    enabled: !!fileName,
  });
};

export const useDownSignalFile = (type: Type) => {
  return useMutation<any, AxiosError, { srcName: string; destName: String }>({
    mutationFn: async ({ srcName, destName }) => {
      // const res = await bdfControllerApi.getBdfFile(type, srcName, { responseType: 'blob' });
      // const url = window.URL.createObjectURL(res.data);
      // const a = document.createElement('a');
      // a.style.display = 'none';
      // a.href = url;
      // a.download = `${destName}.edf`;
      // document.body.appendChild(a);
      // a.click();
      // window.URL.revokeObjectURL(url);
    },
  });
};

//TODO: 인증용이라 환자 ID 안받음
export const useFetchEdfFiles = (type: Type) => {
  return useQuery<RecFile[]>({
    queryKey: ['edf_files', type],
    queryFn: () => {
      // bdfControllerApi.getBdfList(type).then((res) => res.data.sort((a, b) => b.recordingEndTime - a.recordingEndTime))
      return [];
    },
    gcTime: 0,
  });
};

//TODO: 인증용이라 환자 ID 안받음
export const useReloadEdfFiles = () => {
  const client = useQueryClient();
  return useMutation<void, AxiosError>({
    mutationFn: () => client.invalidateQueries({ queryKey: ['edf_files'] }),
  });
};

//TODO: 인증용이라 환자 ID 안받음
export const useDeleteEdfFile = () => {
  const client = useQueryClient();
  return useMutation<void, AxiosError, string[]>({
    mutationFn: async (fileNames) => {
      // bdfControllerApi.deleteEdfFiles({ files: fileNames }).then((res) => res.data)
    },
    onSuccess: () => client.invalidateQueries({ queryKey: ['edf_files'] }),
  });
};

export const useFetchScanSetting = () => {
  return useQuery({
    queryKey: ['scan_config'],
    queryFn: () => configControllerApi.getConfig().then((res) => res.data),
    gcTime: 0,
  });
};
