import { ModuleControllerApi, SessionInfo } from '@MP/api/scan';
import { OrgSocketClient } from '@MP/api/socket/scanOrgSocket';
import { ModuleState, SubscribeId } from '@MP/api/socket/type';
import { DeviceSerial, ScanModule } from './type';

const createInitialModule = (deviceSerial: DeviceSerial) => {
  return {
    deviceSerial: deviceSerial,
    state: {
      curr: ModuleState.OFFLINE,
      prev: ModuleState.OFFLINE,
    },
    transmission: {
      hrv: false,
      eeg: false,
      quality: false,
    },
  };
};

const moduleControllerApi = new ModuleControllerApi(undefined, 'https://scan2-api.dev.ybrainlab.com');

class ModulesStatusManager {
  private orgClient: OrgSocketClient;
  private _modules: Record<DeviceSerial, ScanModule> = {};
  private fire: boolean = false;
  private intervalId: any;

  subscribeIds: SubscribeId[] = [];

  private constructor(orgClient: OrgSocketClient) {
    this.orgClient = orgClient;
  }

  static create(
    orgClient: OrgSocketClient,
    deviceSerials: DeviceSerial[],
    onStatusChange: (modules: Readonly<ScanModule>[]) => void
  ) {
    return new ModulesStatusManager(orgClient).init(deviceSerials, onStatusChange);
  }

  private init(deviceSerials: DeviceSerial[], onStatusChange: (modules: Readonly<ScanModule>[]) => void) {
    this.intervalId = setInterval(() => {
      if (this.fire) {
        onStatusChange(Object.values(this._modules));
        this.fire = false;
      }
    }, 500);
    deviceSerials.forEach((deviceSerial) => {
      this._modules[deviceSerial] = createInitialModule(deviceSerial);
    });
    this.fire = true;
    this.subscribeIds.push(this.orgClient.subscribeOnBattery(this.updateBattery.bind(this)));
    this.subscribeIds.push(this.orgClient.subscribeOnState(this.updateState.bind(this)));
    this.subscribeIds.push(this.orgClient.subscribeOnDisposeProgress(this.updateDisposingProgress.bind(this)));
    this.subscribeIds.push(this.orgClient.subscribeOnQualityTransmission(this.updateQualityTransmission.bind(this)));
    this.subscribeIds.push(this.orgClient.subscribeOnSensorTransmission(this.updateSensorTransmission.bind(this)));
    this.subscribeIds.push(this.orgClient.subscribeOnSession(this.updateSession.bind(this)));

    moduleControllerApi.requestAll();

    return this;
  }

  addModules(deviceSerial: DeviceSerial) {
    this._modules[deviceSerial] = createInitialModule(deviceSerial);
    moduleControllerApi.request(deviceSerial);
    this.fire = true;
  }

  removeModules(deviceSerial: DeviceSerial) {
    delete this._modules[deviceSerial];
    this.fire = true;
  }

  destroy() {
    this._modules = {};
    clearInterval(this.intervalId);
    this.subscribeIds.forEach((id) => {
      this.orgClient.unsubscribe(id);
    });
  }

  private updateQualityTransmission(deviceSerial: DeviceSerial, result: boolean) {
    const module = this._modules[deviceSerial];
    if (module && module.transmission.quality !== result) {
      this._modules[deviceSerial] = { ...module, transmission: { ...module.transmission, quality: result } };
      this.fire = true;
    }
  }

  private updateSensorTransmission(deviceSerial: DeviceSerial, eeg: boolean, hrv: boolean) {
    const module = this._modules[deviceSerial];
    if (module && (module.transmission.eeg !== eeg || module.transmission.hrv !== hrv)) {
      this._modules[deviceSerial] = { ...module, transmission: { quality: module.transmission.quality, eeg, hrv } };
      this.fire = true;
    }
  }

  private updateSession(deviceSerial: DeviceSerial, session: SessionInfo) {
    const module = this._modules[deviceSerial];
    if (module && module.userId !== session.subjectId) {
      this._modules[deviceSerial] = { ...module, userId: session.subjectId };
      this.fire = true;
    }
  }
  private updateDisposingProgress(deviceSerial: DeviceSerial, percentage: number) {
    const module = this._modules[deviceSerial];
    if (module && module.disposingPercentage !== percentage) {
      this._modules[deviceSerial] = { ...module, disposingPercentage: percentage };
      this.fire = true;
    }
  }

  private updateBattery(deviceSerial: DeviceSerial, battery: number, isCharging: boolean) {
    const module = this._modules[deviceSerial];
    if (module && (module.battery !== battery || module.isCharging !== isCharging)) {
      this._modules[deviceSerial] = { ...module, battery, isCharging };
      this.fire = true;
    }
  }
  private updateState(deviceSerial: DeviceSerial, prev: ModuleState, curr: ModuleState) {
    const module = this._modules[deviceSerial];
    if (module && (module.state.prev !== prev || module.state.curr !== curr)) {
      this._modules[deviceSerial] = { ...module, state: { prev, curr }, disposingPercentage: 0 };

      this.fire = true;
    }
  }
}

export default ModulesStatusManager;
